import { Get, ValueOf } from 'type-fest';

import type { LocationObserverModal } from 'features/global';
import * as TS from 'types';
import * as M from 'types/serverModels';
import {
  makeEnumeratedNodesDescription,
  makeRouteTree,
  makeInfiniteNodesDescription,
  makeSearchParamsDescription,
  substitutes,
} from 'utils/RouteTree';
import {
  makeHashParamDescription,
  makeSuperiorNodeDescription,
  makeLeafNodeDescription,
} from 'utils/RouteTree/nodes';

export type SharedParams = { profile?: TS.OAuth2Profile };

export type SharedHash = ValueOf<typeof LocationObserverModal.hashes>;

export type SignInParams = {
  from?: M.URL;
};

export type LandingPageHash = `#${M.LandingPageSection['uuid']}`;

export type ProjectListParams = {
  search?: string;
  languages?: string[];
  subjects?: string[];
  ages?: string[];
  rubrics?: string[];
  formats?: string[];
  sort?: string;
};

export type GroupMonitoringParams = {
  users?: string[];
  projects?: string[];
  dates?: { from?: M.Datetime | null; to?: M.Datetime | null };
};

export type CourseListParams = {
  search?: string;
  subjects?: string[];
  groups?: string[];
};

export type UserPersonalAccountHash = '#all-notifications';

export type CommunityMembersParams = {
  tab?: 'list' | 'map';
  name?: string;
  subject?: string;
  interest?: string;
  lang?: string;
  role?: 'all' | 'educator' | 'member';
  sort?: string;
};

export type CommunityGroupsParams = {
  tab?: 'list' | 'map';
  name?: string;
  type?: M.GroupsType;
  sort?: string;
};

export type QuizListParams = {
  sort?: 'name' | 'date';
  sortDirection?: 'asc' | 'desc';
};

export type GroupMembersParams = {
  tab?: 'list' | 'map';
};

export type ProjectBlogHash = `#${M.BlogMessage['uuid']}`;

export type CourseProgressParams = {
  sort?:
    | 'asc-name'
    | 'desc-name'
    | 'asc-login'
    | 'desc-login'
    | 'asc-progress'
    | 'desc-progress';
};

export type CourseBlogHash = `#${M.BlogMessage['uuid']}`;

export type ProjectBookPassportHash =
  | `#field-${
      | 'kind'
      | 'level'
      | 'description'
      | M.ProjectBookField['code']
      | M.ProjectBookField['uuid']}`
  | `#material-${M.ProjectBookUnit['uuid']}`;

export type ProjectBookStagesHash =
  | `#stage-${M.ProjectBookStage['code'] | M.ProjectBookStage['uuid']}`
  | `#unit-${M.ProjectBookUnit['uuid']}`
  | `#material-${M.ProjectBookUnit['uuid']}`;

export type ProjectBookTaskHash = `#material-${M.ProjectBookUnit['uuid']}`;

export type ProjectBookHistoryParams = {
  dates?: { from?: M.Date | null; to?: M.Date | null };
  authors?: Exclude<M.ProjectBookChange['user'], undefined>[];
  sections?: (
    | Exclude<Get<M.ProjectBookChange, 'change.section'>, 'stages' | undefined>
    | `stage-${M.ProjectBookStage['uuid']}`
  )[];
  events?: Exclude<Get<M.ProjectBookChange, 'change.event'>, undefined>[];
};

export type TestimonialsHash = `#${M.Testimonial['uuid']}`;

export type CourseConstructorRouteTree = {
  kind: 'course-constructor-page';
  tree: typeof routeTree.LANG.course.constructor;
};

export type CoursePageRouteTree = {
  kind: 'course';
  tree: typeof routeTree.LANG.course.list.COURSE;
};

export type ProjectAssignmentRouteTree = {
  kind: 'project-assignment';
  tree: (typeof routeTree.LANG)['project-assignment']['list']['PROJECT_ASSIGNMENT'];
};

export type GroupRouteTree = {
  kind: 'group';
  tree: (typeof routeTree.LANG)['group']['GROUP'];
};

export type PersonalAccountMyProjectsRouteTree = {
  kind: 'personal-account-my-projects';
  tree: (typeof routeTree.LANG)['personal-account']['my-projects'];
};

// NOTE: use unique kind cause of project can be opened from some places(course) and contains opening things(units): course contains units also - path params resolving problem if kind is common(from course). In another places we can use kind from super tree for getSuperTreeRouteParams reuse
export type ProjectPageRouteTree =
  | {
      kind: 'project-list';
      tree: typeof routeTree.LANG.project.list;
    }
  | {
      kind: 'project-book-stage-projects';
      tree: (typeof routeTree.LANG)['project-book']['list']['PROJECT_BOOK']['stages']['STAGE']['info']['projects'];
    }
  | {
      kind: 'project-book-user-stage-projects';
      tree: (typeof routeTree.LANG)['project-book']['list']['PROJECT_BOOK']['users']['USER']['stages']['STAGE']['info']['projects'];
    }
  | {
      kind: 'project-book-results-projects';
      tree: (typeof routeTree.LANG)['project-book']['list']['PROJECT_BOOK']['results']['projects'];
    }
  | {
      kind: 'project-book-user-results-projects';
      tree: (typeof routeTree.LANG)['project-book']['list']['PROJECT_BOOK']['users']['USER']['results']['projects'];
    }
  | {
      kind: 'project-book-recycle-bin-projects';
      tree: (typeof routeTree.LANG)['project-book']['list']['PROJECT_BOOK']['recycle-bin']['projects'];
    }
  | {
      kind: 'project-book-user-recycle-bin-projects';
      tree: (typeof routeTree.LANG)['project-book']['list']['PROJECT_BOOK']['users']['USER']['recycle-bin']['projects'];
    }
  | {
      kind: 'course-track-projects';
      tree: CoursePageRouteTree['tree']['track']['projects'];
    }
  | {
      kind: 'course-progress-projects';
      tree: CoursePageRouteTree['tree']['progress']['projects'];
    }
  | {
      kind: 'course-constructor-page-projects';
      tree: CourseConstructorRouteTree['tree']['COURSE_UUID']['projects'];
    };

export type UnitRouteTree =
  | {
      kind: ProjectPageRouteTree['kind'];
      tree: ProjectPageRouteTree['tree']['PROJECT']['investigation'];
    }
  | {
      kind: CourseConstructorRouteTree['kind'];
      tree: CourseConstructorRouteTree['tree']['COURSE_UUID'];
    }
  | {
      kind: CoursePageRouteTree['kind'];
      tree: CoursePageRouteTree['tree']['track'];
    };

export type ConclusionsRouteTree =
  | {
      kind: ProjectPageRouteTree['kind'];
      tree: ProjectPageRouteTree['tree']['PROJECT']['conclusions'];
    }
  | {
      kind: CoursePageRouteTree['kind'];
      tree: CoursePageRouteTree['tree']['conclusions'];
    }
  | {
      kind: CourseConstructorRouteTree['kind'];
      tree: CourseConstructorRouteTree['tree']['COURSE_UUID']['quiz'];
    }
  | {
      kind: ProjectAssignmentRouteTree['kind'];
      tree: ProjectAssignmentRouteTree['tree']['assignees']['conclusions'];
    }
  | {
      kind: PersonalAccountMyProjectsRouteTree['kind'];
      tree: PersonalAccountMyProjectsRouteTree['tree']['PROJECT']['conclusions'];
    };

export type QuestionnaireRouteTree = {
  kind:
    | ProjectPageRouteTree['kind']
    | ProjectAssignmentRouteTree['kind']
    | GroupRouteTree['kind']
    | PersonalAccountMyProjectsRouteTree['kind'];
  tree:
    | ProjectPageRouteTree['tree']['PROJECT']
    | ProjectPageRouteTree['tree']['PROJECT']['general']
    | ProjectPageRouteTree['tree']['PROJECT']['findings']
    | ProjectAssignmentRouteTree['tree']['findings']
    | ProjectAssignmentRouteTree['tree']['task']
    | ProjectAssignmentRouteTree['tree']['assignees']
    | GroupRouteTree['tree']['projects']['PROJECT']
    | PersonalAccountMyProjectsRouteTree['tree']['PROJECT'];
};

export function getProjectPageRootParams(
  projectPageRouteTree:
    | ProjectPageRouteTree
    | UnitRouteTree
    | ConclusionsRouteTree
    | QuestionnaireRouteTree,
) {
  return {
    PROJECT_BOOK:
      projectPageRouteTree.kind === 'project-book-stage-projects' ||
      projectPageRouteTree.kind === 'project-book-user-stage-projects' ||
      projectPageRouteTree.kind === 'project-book-results-projects' ||
      projectPageRouteTree.kind === 'project-book-user-results-projects' ||
      projectPageRouteTree.kind === 'project-book-recycle-bin-projects' ||
      projectPageRouteTree.kind === 'project-book-user-recycle-bin-projects'
        ? substitutes.fromLocation
        : substitutes.fromPattern,
    USER:
      projectPageRouteTree.kind === 'project-book-user-stage-projects' ||
      projectPageRouteTree.kind === 'project-book-user-results-projects' ||
      projectPageRouteTree.kind === 'project-book-user-recycle-bin-projects'
        ? substitutes.fromLocation
        : substitutes.fromPattern,
    STAGE:
      projectPageRouteTree.kind === 'project-book-stage-projects' ||
      projectPageRouteTree.kind === 'project-book-user-stage-projects'
        ? substitutes.fromLocation
        : substitutes.fromPattern,
    COURSE:
      projectPageRouteTree.kind === 'course-track-projects' ||
      projectPageRouteTree.kind === 'course-progress-projects'
        ? substitutes.fromLocation
        : substitutes.fromPattern,
    COURSE_UUID:
      projectPageRouteTree.kind === 'course-constructor-page' ||
      projectPageRouteTree.kind === 'course-constructor-page-projects'
        ? substitutes.fromLocation
        : substitutes.fromPattern,
  };
}

export function getUnitRootParams(unitRouteTree: UnitRouteTree) {
  switch (unitRouteTree.kind) {
    case 'project-list':
    case 'project-book-stage-projects':
    case 'project-book-user-stage-projects':
    case 'project-book-results-projects':
    case 'project-book-user-results-projects':
    case 'project-book-recycle-bin-projects':
    case 'project-book-user-recycle-bin-projects':
    case 'course-track-projects':
    case 'course-progress-projects':
    case 'course-constructor-page-projects': {
      return {
        ...getProjectPageRootParams(unitRouteTree),
        PROJECT: substitutes.fromLocation,
      };
    }
    case 'course-constructor-page': {
      return {
        COURSE_UUID: substitutes.fromLocation,
        COURSE: substitutes.fromPattern,
        PROJECT_BOOK: substitutes.fromPattern,
        USER: substitutes.fromPattern,
        STAGE: substitutes.fromPattern,
        PROJECT: substitutes.fromPattern,
        PROJECT_ASSIGNMENT: substitutes.fromPattern,
      };
    }
    case 'course': {
      return {
        COURSE: substitutes.fromLocation,
        PROJECT_BOOK: substitutes.fromPattern,
        USER: substitutes.fromPattern,
        STAGE: substitutes.fromPattern,
        PROJECT: substitutes.fromLocation,
        COURSE_UUID: substitutes.fromPattern,
        PROJECT_ASSIGNMENT: substitutes.fromPattern,
      };
    }
  }
}

export function getConclusionsRootParams(
  conclusionsRouteTree: ConclusionsRouteTree,
) {
  switch (conclusionsRouteTree.kind) {
    case 'project-list':
    case 'project-book-stage-projects':
    case 'project-book-user-stage-projects':
    case 'project-book-results-projects':
    case 'project-book-user-results-projects':
    case 'project-book-recycle-bin-projects':
    case 'project-book-user-recycle-bin-projects':
    case 'course-track-projects':
    case 'course-progress-projects':
    case 'course-constructor-page-projects': {
      return {
        ...getProjectPageRootParams(conclusionsRouteTree),
        PROJECT: substitutes.fromLocation,
        PROJECT_ASSIGNMENT: substitutes.fromPattern,
      };
    }
    case 'course': {
      return {
        COURSE: substitutes.fromLocation,
        PROJECT_BOOK: substitutes.fromPattern,
        USER: substitutes.fromPattern,
        STAGE: substitutes.fromPattern,
        PROJECT: substitutes.fromPattern,
        COURSE_UUID: substitutes.fromPattern,
        PROJECT_ASSIGNMENT: substitutes.fromPattern,
      };
    }
    case 'course-constructor-page': {
      return {
        COURSE_UUID: substitutes.fromLocation,
        COURSE: substitutes.fromPattern,
        PROJECT_BOOK: substitutes.fromPattern,
        USER: substitutes.fromPattern,
        STAGE: substitutes.fromPattern,
        PROJECT: substitutes.fromPattern,
        PROJECT_ASSIGNMENT: substitutes.fromPattern,
      };
    }
    case 'project-assignment': {
      return {
        PROJECT_ASSIGNMENT: substitutes.fromLocation,
        COURSE_UUID: substitutes.fromPattern,
        COURSE: substitutes.fromPattern,
        PROJECT_BOOK: substitutes.fromPattern,
        USER: substitutes.fromPattern,
        STAGE: substitutes.fromPattern,
        PROJECT: substitutes.fromPattern,
      };
    }
    case 'personal-account-my-projects': {
      return {
        PROJECT_ASSIGNMENT: substitutes.fromPattern,
        COURSE_UUID: substitutes.fromPattern,
        COURSE: substitutes.fromPattern,
        PROJECT_BOOK: substitutes.fromPattern,
        USER: substitutes.fromPattern,
        STAGE: substitutes.fromPattern,
        PROJECT: substitutes.fromPattern,
      };
    }
  }
}

export function getQuestionnaireRootParams(
  questionnaireTree: QuestionnaireRouteTree,
) {
  switch (questionnaireTree.kind) {
    case 'project-list':
    case 'project-book-stage-projects':
    case 'project-book-user-stage-projects':
    case 'project-book-results-projects':
    case 'project-book-user-results-projects':
    case 'project-book-recycle-bin-projects':
    case 'project-book-user-recycle-bin-projects':
    case 'course-track-projects':
    case 'course-progress-projects':
    case 'course-constructor-page-projects': {
      return {
        ...getProjectPageRootParams(questionnaireTree),
        PROJECT: substitutes.fromLocation,
        PROJECT_ASSIGNMENT: substitutes.fromPattern,
        GROUP: substitutes.fromPattern,
      };
    }
    case 'project-assignment': {
      return {
        PROJECT: substitutes.fromPattern,
        PROJECT_BOOK: substitutes.fromPattern,
        USER: substitutes.fromPattern,
        STAGE: substitutes.fromPattern,
        COURSE: substitutes.fromPattern,
        COURSE_UUID: substitutes.fromPattern,
        PROJECT_ASSIGNMENT: substitutes.fromLocation,
        GROUP: substitutes.fromPattern,
      };
    }
    case 'group': {
      return {
        PROJECT: substitutes.fromLocation,
        PROJECT_BOOK: substitutes.fromPattern,
        USER: substitutes.fromPattern,
        STAGE: substitutes.fromPattern,
        COURSE: substitutes.fromPattern,
        COURSE_UUID: substitutes.fromPattern,
        PROJECT_ASSIGNMENT: substitutes.fromPattern,
        GROUP: substitutes.fromLocation,
      };
    }
    case 'personal-account-my-projects': {
      return {
        PROJECT: substitutes.fromPattern,
        PROJECT_BOOK: substitutes.fromPattern,
        USER: substitutes.fromPattern,
        STAGE: substitutes.fromPattern,
        COURSE: substitutes.fromPattern,
        COURSE_UUID: substitutes.fromPattern,
        PROJECT_ASSIGNMENT: substitutes.fromPattern,
        GROUP: substitutes.fromPattern,
      };
    }
  }
}

const questionnaireTree = {
  form: makeInfiniteNodesDescription('QUESTIONNAIRE_UUID', {}),
  table: makeInfiniteNodesDescription('BATCH', {}),
};

const quizTree = makeSuperiorNodeDescription(
  {
    view: makeInfiniteNodesDescription('QUIZ_UUID', {}),
    form: makeInfiniteNodesDescription('QUIZ_UUID', {}),
    constructor: makeInfiniteNodesDescription('QUIZ_UUID', {}),
  },
  makeSearchParamsDescription<QuizListParams>(),
);

const projectPageTree = {
  general: { ...questionnaireTree },
  investigation: { units: makeInfiniteNodesDescription('UNIT_UUID', {}) },
  findings: { ...questionnaireTree },
  conclusions: quizTree,
  quiz: quizTree,
  discussion: null,
  blog: makeInfiniteNodesDescription(
    'BLOG_MESSAGE',
    {},
    undefined,
    makeHashParamDescription<ProjectBlogHash>(),
  ),
  participants: null,
  ...questionnaireTree,
};

const projectBookTaskTree = {
  form: makeInfiniteNodesDescription(
    'TASK_UUID',
    {},
    undefined,
    makeHashParamDescription<ProjectBookTaskHash>(),
  ),
};

const projectBookPageTree = {
  passport: makeLeafNodeDescription(
    undefined,
    makeHashParamDescription<ProjectBookPassportHash>(),
  ),
  members: null,
  stages: makeInfiniteNodesDescription('STAGE', {
    info: makeSuperiorNodeDescription(
      {
        projects: makeInfiniteNodesDescription('PROJECT', projectPageTree),
        task: { ...projectBookTaskTree },
      },
      undefined,
      makeHashParamDescription<ProjectBookStagesHash>(),
    ),
  }),
  results: {
    projects: makeInfiniteNodesDescription('PROJECT', projectPageTree),
  },
  evaluation: null,
  reflexion: null,
  history: makeLeafNodeDescription(
    makeSearchParamsDescription<ProjectBookHistoryParams>(),
  ),
  notifications: makeLeafNodeDescription(),
  'recycle-bin': {
    projects: makeInfiniteNodesDescription('PROJECT', projectPageTree),
  },
};

const rawTree = makeEnumeratedNodesDescription(['ru', 'en', 'es'], 'LANG', {
  'landing-page': makeInfiniteNodesDescription(
    'LANDING_PAGE_CODE',
    {},
    undefined,
    undefined,
    undefined,
    makeHashParamDescription<LandingPageHash>(),
  ),
  project: {
    constructor: makeInfiniteNodesDescription('PROJECT_UUID', {}),
    list: makeInfiniteNodesDescription(
      'PROJECT',
      projectPageTree,
      makeSearchParamsDescription<ProjectListParams>(),
    ),
  },
  'project-book': {
    intro: makeLeafNodeDescription(
      makeSearchParamsDescription<{ group: string }>(),
    ),
    list: makeInfiniteNodesDescription('PROJECT_BOOK', {
      ...projectBookPageTree,
      users: makeInfiniteNodesDescription('USER', projectBookPageTree),
    }),
  },
  'project-assignment': {
    list: makeInfiniteNodesDescription(
      'PROJECT_ASSIGNMENT',
      {
        about: makeSuperiorNodeDescription({
          ...questionnaireTree,
          conclusions: quizTree,
        }),
        assignees: makeSuperiorNodeDescription({
          ...questionnaireTree,
          conclusions: quizTree,
        }),
        task: makeSuperiorNodeDescription({
          ...questionnaireTree,
          conclusions: quizTree,
        }),
        answers: makeSuperiorNodeDescription({
          ...questionnaireTree,
          conclusions: quizTree,
        }),
        findings: makeSuperiorNodeDescription({
          ...questionnaireTree,
        }),
      },
      makeSearchParamsDescription<ProjectListParams>(),
    ),
  },
  course: {
    constructor: makeInfiniteNodesDescription('COURSE_UUID', {
      projects: makeInfiniteNodesDescription('PROJECT', projectPageTree),
      units: makeInfiniteNodesDescription('UNIT_UUID', {}),
      quiz: quizTree,
    }),
    list: makeInfiniteNodesDescription(
      'COURSE',
      {
        cover: null,
        track: makeSuperiorNodeDescription({
          projects: makeInfiniteNodesDescription('PROJECT', projectPageTree),
          units: makeInfiniteNodesDescription('UNIT_UUID', {}),
          quiz: quizTree,
        }),
        progress: makeSuperiorNodeDescription(
          {
            projects: makeInfiniteNodesDescription('PROJECT', projectPageTree),
            quiz: quizTree,
          },
          makeSearchParamsDescription<CourseProgressParams>(),
        ),
        results: null,
        projects: null,
        blog: makeInfiniteNodesDescription(
          'BLOG_MESSAGE',
          {},
          undefined,
          makeHashParamDescription<ProjectBlogHash>(),
        ),
        conclusions: quizTree,
        members: null,
        quiz: quizTree,
      },
      makeSearchParamsDescription<CourseListParams>(),
    ),
  },
  webinar: {
    list: {
      schedular: null,
      history: null,
    },
    page: makeInfiniteNodesDescription('WEBINAR_UUID', {}),
  },
  article: makeInfiniteNodesDescription('CODE', {}),
  partners: null,
  shop: {
    catalog: null,
    tariff: makeInfiniteNodesDescription('UUID', {}),
  },
  group: makeInfiniteNodesDescription('GROUP', {
    about: null,
    discussion: null,
    rating: null,
    projects: makeInfiniteNodesDescription(
      'PROJECT',
      questionnaireTree,
      makeSearchParamsDescription<GroupMonitoringParams>(),
    ),
    'project-books': null,
    settings: null,
  }),
  users: makeInfiniteNodesDescription('USER', {
    profile: null,
    'personal-information': null,
    perks: null,
    projects: null,
    // TODO: return it when the layout and services are ready(after MVP)
    // tests: null,
    groups: null,
    courses: null,
    'to-moderator': {
      features: null,
      courses: null,
      history: null,
    },
  }),
  'personal-account': {
    'personal-account': makeLeafNodeDescription(
      undefined,
      makeHashParamDescription<UserPersonalAccountHash>(),
    ),
    perks: makeInfiniteNodesDescription('PERK_GROUP', { all: null }),
    // TODO: return it when the layout and services are ready(after MVP)
    // 'my-assignments': null,
    'my-project-books': null,
    'my-projects': makeInfiniteNodesDescription('PROJECT', {
      ...questionnaireTree,
      conclusions: quizTree,
    }),
    // TODO: return it when the layout and services are ready(after MVP)
    // 'my-tests': null,
    'my-courses': null,
    'my-groups': null,
    'my-sensors': null,
    package: {
      features: null,
      courses: null,
      history: null,
    },
    settings: null,
  },
  community: {
    search: {
      members: makeLeafNodeDescription(
        makeSearchParamsDescription<CommunityMembersParams>(),
      ),
      groups: makeLeafNodeDescription(
        makeSearchParamsDescription<CommunityGroupsParams>(),
      ),
    },
  },
  info: {
    news: null,
    blog: null,
    support: null,
    feedback: null,
    'about-us': null,
    'user-agreement': null,
  },
  ideas: null,
  testimonials: makeLeafNodeDescription(
    undefined,
    makeHashParamDescription<TestimonialsHash>(),
  ),
  'ui-kit': null,
  'sign-in': makeLeafNodeDescription(
    makeSearchParamsDescription<SignInParams>(),
  ),
  'sign-up': null,
  'password-reset': makeInfiniteNodesDescription('UUID', {}),
  'confirm-email': makeInfiniteNodesDescription('UUID', {}),
  handbook: makeInfiniteNodesDescription('CODE', {
    batches: makeInfiniteNodesDescription('BATCH', {}),
    topic: makeInfiniteNodesDescription('TOPIC', {
      batches: makeInfiniteNodesDescription('BATCH', {}),
    }),
  }),
  quiz: quizTree,
  mesch: {
    'material-launcher': null,
  },
});

export const routeTree = makeRouteTree<
  typeof rawTree,
  SharedParams,
  SharedHash
>(rawTree);

type UserProfilePath = Extract<
  keyof typeof routeTree.LANG.users.USER,
  | 'profile'
  | 'personal-information'
  | 'perks'
  | 'projects'
  | 'groups'
  | 'courses'
>;

export const getUserProfileLink = (
  lang: TS.Language,
  login?: string,
  path: UserProfilePath = 'profile',
) => {
  if (typeof login !== 'string') {
    return;
  }

  return routeTree.LANG.users.USER[path].getPath({
    routeParams: { LANG: lang, USER: login },
  });
};

type GroupProfilePath = Extract<
  keyof typeof routeTree.LANG.group.GROUP,
  'about' | 'discussion' | 'rating' | 'monitoring'
>;

export const getGroupProfileLink = (
  lang: TS.Language,
  uuid: string | undefined,
  path: GroupProfilePath = 'about',
) => {
  if (typeof uuid !== 'string') {
    return;
  }

  return routeTree.LANG.group.GROUP[path].getPath({
    routeParams: { LANG: lang, GROUP: uuid },
  });
};
