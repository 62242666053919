import * as M from 'types/serverModels';

import { makeService } from '../utils';

type PathInputData = {};

type QueryInputData = {
  batch: M.Batch['code'];
};

type InputData = PathInputData & QueryInputData;

type APIOutputData = {
  articles?: M.Article[];
  batch?: M.Batch;
};

type OutputData = M.ArticleBatch;

function extractResponse(data: APIOutputData): OutputData {
  if (!data.batch) {
    throw Error('undefined batch');
  }

  return {
    ...data.batch,
    articles: data.articles || [],
  };
}

export const list = makeService<InputData, APIOutputData, OutputData>(
  (inputData, convertToSearchParams) => {
    const { batch, ticket } = inputData;

    return `article/?${convertToSearchParams({
      batch,
      ticket,
    })}`;
  },
  'api-v2-get',
  false,
  extractResponse,
);
