import * as M from 'types/serverModels';

import { makeService } from '../utils';

type PathInputData = {
  uuid_or_code: M.Article['code'] | M.Article['uuid'];
};

type QueryInputData = {};

type InputData = PathInputData & QueryInputData;

type APIOutputData = {
  article?: M.Article;
};

type OutputData = {
  article: M.Article;
};

function extractResponse(data: APIOutputData): OutputData {
  if (!data.article) {
    throw Error('undefined article');
  }

  return {
    article: data.article,
  };
}

export const get = makeService<InputData, APIOutputData, OutputData>(
  ({ ticket, uuid_or_code }, convertToSearchParams) =>
    `article/${uuid_or_code}/?${convertToSearchParams({ ticket })}`,
  'api-v2-get',
  false,
  extractResponse,
);
